<template>
  <div class="share">
    <div class="left">
      <p class="left-title">分享配置：<span>(用于用于配置小程序分享时的文案和图片，建议尺寸500*400)</span></p>
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="80px">
        <section class="form-main">
          <el-form-item label="分享文案" prop="shareWriting">
            <el-input v-model.trim="params.shareWriting" autocomplete="off" maxlength="16"
              placeholder="请填写分享文案"></el-input>
          </el-form-item>
          <!-- 图片上传 -->
          <el-form-item label="图片上传" prop="sharePic">
            <div class="upload-btn" @click="onUpload()">
              <img v-if="params.sharePicUrl" :src="params.sharePicUrl" class="upload-img">
              <i v-else class="el-icon-plus uploader-icon"></i>
            </div>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </div>
    <div class="right">
      <p>小程序展示样式</p>
      <img src="~@/assets/images/mini/share.png" alt="">
    </div>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import {
  getShareInfo,
  getShareEdit,
} from "@/api/shop/mini";
import { rules } from "@/db/rules";
import UpLoad from "@/components/upload";
import {
  getFileList
} from "@/api/common";
export default {
  components: { UpLoad },
  data() {
    return {
      rules,
      params: {
        sharePicUrl: "", //图片路径
        shopId: "",//店铺ID
        shareWriting: "", 
        sharePic: "", //图片id
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getShareInfo();
  },
  methods: {
    // 【请求】分享配置查询
    getShareInfo() {
      let data = {
        shopId: this.params.shopId
      };
      getShareInfo(data).then(res => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.params = res.data;
          }
        }
      })
    },

    // 【请求】小程序wifi编辑
    getShareEdit() {
      let data = this.params;
      getShareEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getShareInfo();
        }
      })
    },


    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds
      }
      getFileList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.params.sharePicUrl = res.data[0].url
        }
      })
    },

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload()
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      console.log('fileIds: ', fileIds);
      this.params.sharePic = fileIds;
      this.getFileList(fileIds);
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("params", this.params);
          this.getShareEdit();
        } else {
          return false;
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.share {
  display: flex;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.upload-btn:hover {
  border-color: #409EFF;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}

.foot-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1rem;
}

.left {
  width: 60%;
}

.left-title {
  margin-bottom: .15rem;

  span {
    font-size: .14rem;
    color: #999999;
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: .1rem 0;
  }
}
</style>


